.vectorMapBox {
    width: 100%;
    height: 500px;
    overflow: hidden;
    margin: 80px 0;
}


.vectorMapBox svg>g [aria-labelledby="id-79-title"] {
    display: none;
}

.vectorMapImage {
    display: none;
}

.vectorMapTooltip {
    padding: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.vectormapTooltipImage {
    width: 50px;
    margin-right: 10px;
    width: 50px;
    width: 40px;
}


.vectormapTooltipContent {
    text-align: left;
}

.vectormapTooltipContentHeading {
    color: #001d2f;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}

.vectormapTooltipContent>p {
    color: #001d2f;
    font-size: 12px;
    margin: 0;
}

.vectormapTooltipContent>p>strong {
    font-weight: 500;
    margin-right: 5px;
}