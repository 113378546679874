.ph-item {
    width: 32%;
    height: 300px;
}

/* Pricing */
section.pricing-sect {
    background: #f6f6f6;
}

section.pricing-sect.transparent {
    background-color: transparent !important;
}

section.pricing-sect .in-pricing-sect {
    padding: 70px 0;
}

.pricing-row {
    display: flex;
    justify-content: space-between;
}

.pricing-row .pricing-items {
    width: 33.333333%;
    position: relative;
}
.pricing-row .pricing-items.active {
    margin-top: -20px;
    position: relative;
    z-index: 1;
}
.pricing-row .pricing-items .in-pricing-items {
    border: 1px solid rgb(0 0 0 / 10%);
    border-radius: 10px;
    overflow: hidden;
}

.pricing-row .pricing-items.active .in-pricing-items {
    border-color: #00b73a;
    outline: 2px solid #00b73a;
    padding-bottom: 20px;
    background: #ffffff;
}

.pricing-row .pricing-items .in-pricing-items .head {
    padding: 30px 30px 0;
    background: #f8f8f8;
}
.pricing-row .pricing-items.active .in-pricing-items .head {
    background: #ffffff;
    padding: 50px 30px 0;
}
.pricing-row .pricing-items .in-pricing-items .head span.save {
    height: 24px;
    background: #d9f4e2;
    font-size: 12px;
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
    color: #000000;
    padding: 0 15px;
    border-radius: 30px;
    margin-bottom: 15px;
    font-weight: 500;
}

.pricing-row .pricing-items .in-pricing-items .head h3.price-title {
    font-size: 1.6rem;
    font-weight: 700;
    color: #001d2f;
}

.pricing-row .pricing-items .in-pricing-items .head h3.price-title span {
    color: #00b73a;
    font-size: 1.2rem;
}

.pricing-row .pricing-items .in-pricing-items .head strong.price {
    color: #00b73a;
    font-weight: 700;
    font-size: 26px;
    display: inline-flex;
    line-height: 26px;
    margin-bottom: 15px;
    margin-top: 10px;
}

.pricing-row .pricing-items .in-pricing-items .head strong.price span {
    font-weight: 400;
    font-size: 16px;
}

.pricing-row .pricing-items .in-pricing-items .head p {
    font-size: 16px;
    line-height: normal;
    margin-bottom: 15px;
    height: 70px;
}

.pricing-row .pricing-items .in-pricing-items .head a.select-plan-btn {
    background: #bdbdbd;
    text-decoration: none;
    width: 100%;
    display: inline-flex;
    height: 50px;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    color: #ffffff;
    margin-bottom: 35px;
}

.pricing-row .pricing-items.active .in-pricing-items .head a.select-plan-btn,
.pricing-row .pricing-items .in-pricing-items .head a.select-plan-btn:hover {
    background: #00b73a;
}

.pricing-row .pricing-items .in-pricing-items .head .include {
    height: 50px;
    background: #b2cee8;
    width: fit-content;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-left: -30px;
    padding: 0 15px;
    position: relative;
    font-size: 16px;
    font-weight: 700;
    color: #000000;
}

.pricing-row .pricing-items .in-pricing-items .head .include::after {
    content: "";
    border-right: 10px;
    position: absolute;
    right: 0;
    width: 20px;
    background: linear-gradient(70deg, rgba(178, 206, 232, 1) 48%, rgba(255, 255, 255, 1) 0%);
    right: -20px;
    height: 50px;
}

.pricing-row .pricing-items .in-pricing-items .most-popular {
    background: #00b73a;
    width: fit-content;
    position: absolute;
    top: -20px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    border-radius: 30px;
    left: 0;
    right: 0;
    margin: auto;
    color: #ffffff;
}
.pricing-row .pricing-items .in-pricing-items .content {}

.pricing-row .pricing-items .in-pricing-items .content ul.plan-list {
    padding: 0;
    list-style: none;
    margin: 0;
    display: flex;
    flex-direction: column;
}

.pricing-row .pricing-items .in-pricing-items .content ul.plan-list li {
    height: 50px;
    padding: 10px 20px;
    border-bottom: 1px solid #e2e2e4;
    font-size: 14px;
    align-items: center;
    display: flex;
    line-height: normal;
    background-color: #ffffff;
}

.pricing-row .pricing-items .in-pricing-items .content ul.plan-list li:first-child {
    border-top: 1px solid #e2e2e2;
}

.pricing-row .pricing-items .in-pricing-items .content ul.plan-list li:last-child {
    border-bottom: none;
}
.pricing-row .pricing-items .in-pricing-items .content ul.plan-list li:nth-child(odd) {
    background: #f8f8f8;
}
.pricing-row .pricing-items .in-pricing-items .content ul.plan-list li span.icon {
    height: 16px;
    width: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 7px;
    font-size: 16px;
    line-height: 16px;
}
.green-text{
    color: #00a434;
}
.red-text{
    color: red;
}
/* pricing responsive */
@media all and (max-width:992px){
    .pricing-row {
        display: flex;
        overflow-x: scroll;
        padding-bottom: 15px;
        overflow-y: unset;
        padding-top: 50px;
    }
    .pricing-row .pricing-items {
        width: 300px;
        position: relative;
        flex: 0 0 300px;
    }
    .pricing-row .pricing-items .in-pricing-items .head h3.price-title {
        font-size: 20px;
        font-weight: 600;
    }
    .pricing-row .pricing-items .in-pricing-items .head strong.price {
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
    }
    .pricing-row .pricing-items .in-pricing-items .head strong.price span {
        font-size: 12px;
    }
    .pricing-row .pricing-items .in-pricing-items .head p {
        font-size: 14px;
        line-height: 20px;
    }
    .pricing-row .pricing-items .in-pricing-items .head a.select-plan-btn {
        height: 40px;
        margin-bottom: 25px;
    }
    .pricing-row .pricing-items .in-pricing-items .head .include {
        height: 40px;
        margin-left: -20px;
        padding: 0 10px;
        font-size: 14px;
        font-weight: 600;
    }
    .pricing-row .pricing-items .in-pricing-items .head .include::after {
        height: 40px;
    }
    .pricing-row .pricing-items .in-pricing-items .head {
        padding: 20px 20px 0;
    }
    .pricing-row .pricing-items.active .in-pricing-items .head {
        padding: 40px 20px 0;
    }
    .pricing-row .pricing-items .in-pricing-items .content ul.plan-list li {
        height: 40px;
        padding: 10px 15px;
        font-size: 13px;
    }
}
@media all and (max-width:768px){
    .pricing-row {
        display: flex;
        overflow-x: unset;
        padding-bottom: 0;
        overflow-y: unset;
        padding-top: 0;
        flex-direction: column;
    }
    .pricing-row .pricing-items {
        width: 100%;
        position: relative;
        flex: 0 0 100%;
        margin: 0 0 15px !important;
    }
    .pricing-row .pricing-items.active {
        margin-top: 0px;
        order: -1;
    }
}
